<template>
  <div>
    <!-- <div class="card my-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <button
              class="btn mx-1 btn-timeseries"
              @click="Filter('thisMonth')"
              v-bind:class="{
                'btn-info': btnActive.month,
                'btn-outline-info': btnActive.month == false,
              }"
            >
              Bulan Ini
            </button>
            <button
              class="btn mx-1 btn-timeseries"
              @click="Filter('lastMonth')"
              v-bind:class="{
                'btn-info': btnActive.lastMonth,
                'btn-outline-info': btnActive.lastMonth == false,
              }"
            >
              Bulan Kemarin
            </button>
            <button
              class="btn mx-1 btn-timeseries"
              @click="Filter('thisYear')"
              v-bind:class="{
                'btn-info': btnActive.year,
                'btn-outline-info': btnActive.year == false,
              }"
            >
              Tahun Ini
            </button>
            <button
              class="btn mx-1 btn-timeseries"
              @click="periodFilter()"
              v-bind:class="{
                'btn-info': btnActive.period,
                'btn-outline-info': btnActive.period == false,
              }"
            >
              Periode
            </button>
          </div>
        </div>

        <div
          class="
            row
            align-items-center
            justify-content-between
            card-spacer
            flex-grow-1
          "
        >
          <span
            class="symbol symbol-50 symbol-light-primary mr-2"
            v-if="tooltipPeriod != 'custom'"
          >
            <button
              class="btn btn-success mr-1 d-inline"
              v-b-tooltip.hover
              title="Table"
              @click="$router.push('/report/payment-report')"
            >
              <i class="fas fa-table px-0"></i> Table
            </button>
          </span>

          <div class="col-12 col-md-9" v-if="tooltipPeriod == 'custom'">
            
            <div class="row align-items-center justify-content-start mt-n3">
              <div class="col-md-12">
                <div class="row">
                  <span class="d-none d-sm-block">
                    <div class="row">
                      <div class="col">
                        <b-input-group>
                          <template #prepend>
                            <button
                              class="btn btn-success mr-1 d-inline"
                              v-b-tooltip.hover
                              title="Table"
                              @click="$router.push('/report/payment-report')"
                            >
                              <i class="fas fa-table px-0"></i> Table
                            </button>
                            <b-button
                              squared
                              @click="resetFilter"
                              variant="danger"
                              >Reset</b-button
                            >
                            <b-button
                              squared
                              @click="generalFilter"
                              variant="success"
                              >Cari</b-button
                            >
                          </template>
                          <b-form-input
                            v-model="filter.start_date"
                            type="text"
                            placeholder="Tanggal Awal"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.start_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                          <b-button squared variant="light">s/d</b-button>
                          <b-form-input
                            v-model="filter.end_date"
                            type="text"
                            placeholder="Tanggal Akhir"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.end_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </span>
                </div>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <button
                            class="btn btn-success mr-1 d-inline"
                            v-b-tooltip.hover
                            title="Table"
                            @click="$router.push('/report/payment-report')"
                          >
                            <i class="fas fa-table px-0"></i>
                          </button>
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="generalFilter"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 d-flex flex-column text-right">
            <span
              class="text-dark-75 font-weight-bolder font-size-h3"
              v-if="sumLoad == false"
              >Rp 0</span
            >
            <span
              class="text-dark-75 font-weight-bolder font-size-h3"
              v-else-if="sumLoad == true"
              >Rp {{ parseInt(sum[0].total).toLocaleString("id-ID") }}</span
            >
            <span class="text-muted font-weight-bold mt-2"
              >Pendapatan Klinik</span
            >
          </div>
        </div>
      </div>
    </div> -->

    <div class="card my-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <button
              class="btn mx-1 btn-timeseries"
              @click="Filter('thisMonth')"
              v-bind:class="{
                'btn-info': btnActive.month,
                'btn-outline-info': btnActive.month == false,
              }"
            >
              Bulan Ini
            </button>
            <button
              class="btn mx-1 btn-timeseries"
              @click="Filter('lastMonth')"
              v-bind:class="{
                'btn-info': btnActive.lastMonth,
                'btn-outline-info': btnActive.lastMonth == false,
              }"
            >
              Bulan Kemarin
            </button>
            <button
              class="btn mx-1 btn-timeseries"
              @click="Filter('thisYear')"
              v-bind:class="{
                'btn-info': btnActive.year,
                'btn-outline-info': btnActive.year == false,
              }"
            >
              Tahun Ini
            </button>
            <button
              class="btn mx-1 btn-timeseries"
              @click="periodFilter()"
              v-bind:class="{
                'btn-info': btnActive.period,
                'btn-outline-info': btnActive.period == false,
              }"
            >
              Periode
            </button>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-end">
              <span class="symbol symbol-50 symbol-light-primary mr-2">
                <button
                  class="btn btn-success mr-1 d-inline"
                  v-b-tooltip.hover
                  title="Table"
                  @click="$router.push('/report/payment-report')"
                >
                  <i class="fas fa-table px-0"></i> Table
                </button>
              </span>
            </div>
          </div>
        </div>

        <div
          class="
            row
            align-items-center
            justify-content-between
            card-spacer
            flex-grow-1
          "
        >
          <div class="col-12 col-md-9" >
            <!-- Filter -->
            <div class="row align-items-center justify-content-start mt-n3" v-if="tooltipPeriod == 'custom'">
              <div class="col-md-12">
                <div class="row">
                  <span class="d-none d-sm-block">
                    <div class="row">
                      <div class="col">
                        <b-input-group>
                          <template #prepend>
                            <!-- <button
                              class="btn btn-success mr-1 d-inline"
                              v-b-tooltip.hover
                              title="Table"
                              @click="$router.push('/report/payment-report')"
                            >
                              <i class="fas fa-table px-0"></i> Table
                            </button> -->
                            <b-button
                              squared
                              @click="resetFilter"
                              variant="danger"
                              >Reset</b-button
                            >
                            <b-button
                              squared
                              @click="generalFilter"
                              variant="success"
                              >Cari</b-button
                            >
                          </template>
                          <b-form-input
                            v-model="filter.start_date"
                            type="text"
                            placeholder="Tanggal Awal"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.start_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                          <b-button squared variant="light">s/d</b-button>
                          <b-form-input
                            v-model="filter.end_date"
                            type="text"
                            placeholder="Tanggal Akhir"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.end_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </span>
                </div>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <button
                            class="btn btn-success mr-1 d-inline"
                            v-b-tooltip.hover
                            title="Table"
                            @click="$router.push('/report/payment-report')"
                          >
                            <i class="fas fa-table px-0"></i>
                          </button>
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="generalFilter"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 d-flex flex-column text-right">
            <span
              class="text-dark-75 font-weight-bolder font-size-h3"
              v-if="sumLoad == false"
              >Rp 0</span
            >
            <span
              class="text-dark-75 font-weight-bolder font-size-h3"
              v-else-if="sumLoad == true"
              >Rp {{ parseInt(sum[0].total).toLocaleString("id-ID") }}</span
            >
            <span class="text-muted font-weight-bold mt-2"
              >Pendapatan Klinik</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card my-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <span class="font-weight-bold"
              >Statistik Pendapatan Berdasarkan Jenis Pembayaran</span
            >
          </div>
          <div class="col-md-12">
            <apexchart
              class="card-rounded-bottom"
              :options="chartOptions"
              :series="series"
              type="bar"
              width="100%"
              height="350"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="card my-1">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <span class="font-weight-bold"
              >Statistik Pendapatan Berdasarkan Produk Layanan</span
            >
          </div>
          <div class="col-md-12">
            <apexchart
              class="card-rounded-bottom"
              :options="chartOptions2"
              :series="series2"
              type="bar"
              width="100%"
              height="350"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      series: [
        {
          name: "Jumlah :",
          data: [],
        },
      ],
      series2: [
        {
          name: "Jumlah :",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: true,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "thisMonth",
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      tooltipPeriod: "thisMonth",

      // chartOptions

      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        colors: ["#245590"],
        tooltip: {
          y: {
            formatter: function (val) {
              // return val;
              return parseInt(val).toLocaleString("id-ID");
            },
          },
        },
        // title: {
        //   text: "Statistik Berdasarkan Jenis Pembayaran",
        //   align: "left",
        //   margin: 10,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize: "14px",
        //     fontWeight: "bolder",
        //   },
        // },
      },

      // chartOptions2
      chartOptions2: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        colors: ["#245590"],
        tooltip: {
          y: {
            formatter: function (val) {
              return parseInt(val).toLocaleString("id-ID");
            },
          },
        },
        // title: {
        //   text: "Statistik Berdasarkan Produk Layanan",
        //   align: "left",
        //   margin: 10,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize: "14px",
        //     fontWeight: "bolder",
        //   },
        // },
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      this.getData();
      this.getDataService();
      mapGetters(["layoutConfig"]);

      if (tool == "thisMonth") {
        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "thisMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (tool == "lastMonth") {
        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (tool == "thisYear") {
        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "thisYear";

        // hide period
        this.onShowPeriod = false;
      }
    },
    generalFilter() {
      this.tooltipPeriod = "custom";
      this.chartLoad = false;
      this.getData();
      this.getDataService();
      mapGetters(["layoutConfig"]);
    },
    periodFilter() {
      this.tooltipPeriod = "custom";

      // set btn Active
      this.btnActive.days = false;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = true;
    },
    resetFilter() {
      this.chartLoad = false;
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.getData();
      this.getDataService();
      mapGetters(["layoutConfig"]);
    },

    async getSummary() {
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      this.sum = await module.get(
        "payments-reports-summary-amount-charts",
        `${filterParams}`
      );

      console.log(this.sum, "SUM");
      if (this.sum.length == 0) {
        this.sumLoad = false;
      } else {
        this.sumLoad = true;
      }
    },

    async getDataService(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get(
        "payment-details-service-chart",
        `${filterParams}`
      );

      this.series2[0].data = response.map((datas) => datas.price_int);
      window.arrData2 = response.map((datas) => String(datas.name));
      this.chartLoad = true;
      console.log(this.series2[0].data);

      // this.series[0].data = patient.map((patient) => parseFloat(patient.price));
      // window.arrData = patient.map((patient) => String(patient.ref_name));
      // this.chartLoad = true;

      this.chartOptions2 = {
        ...this.chartOptions2,
        ...{
          xaxis: {
            categories: window.arrData2,
            title: {
              text: "",
            },
          },
        },
      };
    },
    // async getDataService(tool) {
    //   window.arrData = [];
    //   let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
    //   let response = await module.get(
    //     "payment-details-service-chart",
    //     `${filterParams}`
    //   );

    //   this.series2[0].data = response.map((datas) => datas.price_int);
    //   window.arrData2 = response.map((datas) => String(datas.name));
    //   this.chartLoad = true;
    //   console.log(this.series2[0].data);

    //   this.chartOptions2 = {
    //     chart: {
    //       type: "bar",
    //       height: 150,
    //       toolbar: {
    //         show: false,
    //       },
    //       // zoom: {
    //       //   enabled: false
    //       // },
    //       // sparkline: {
    //       //   enabled: true
    //       // }
    //     },
    //     // plotOptions: {},
    //     plotOptions: {
    //       bar: {
    //         horizontal: false,
    //         borderRadius: 0,
    //         columnWidth: "40%",

    //         // dataLabels: {
    //         //     position: 'top',
    //         //     maxItems: 100,
    //         //     hideOverflowingLabels: true,
    //         //     orientation: horizontal
    //         // }
    //       },
    //     },
    //     legend: {
    //       show: false,
    //     },
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     fill: {
    //       type: "solid",
    //       opacity: 1,
    //     },
    //     stroke: {
    //       curve: "smooth",
    //       show: true,
    //       width: 3,
    //     },
    //     xaxis: {
    //       categories: window.arrData2,
    //       axisBorder: {
    //         show: true,
    //       },
    //       crosshairs: {
    //         show: true,
    //         position: "front",
    //         stroke: {
    //           color: this.layoutConfig("colors.gray.gray-300"),
    //           width: 1,
    //           dashArray: 3,
    //         },
    //       },
    //       tooltip: {
    //         enabled: false,
    //       },
    //       labels: {
    //         show: true,
    //         style: {
    //           colors: [],
    //           fontSize: "7px",
    //           // fontFamily: 'Helvetica, Arial, sans-serif',
    //           // fontWeight: 400,
    //           cssClass: "apexcharts-xaxis-label",
    //         },
    //       },
    //     },
    //     yaxis: {
    //       axisBorder: {
    //         show: true,
    //       },
    //       show: true,
    //       min: 0,
    //       labels: {
    //         show: true,
    //         style: {
    //           colors: [],
    //           fontSize: "7px",
    //           // fontFamily: 'Helvetica, Arial, sans-serif',
    //           // fontWeight: 400,
    //         },
    //       },
    //     },
    //     scales: {
    //       xAxes: [
    //         {
    //           barPercentage: 0.4,
    //         },
    //       ],
    //     },
    //     states: {
    //       normal: {
    //         filter: {
    //           type: "none",
    //           value: 0,
    //         },
    //       },
    //       hover: {
    //         filter: {
    //           type: "none",
    //           value: 0,
    //         },
    //       },
    //       active: {
    //         allowMultipleDataPointsSelection: false,
    //         filter: {
    //           type: "none",
    //           value: 0,
    //         },
    //       },
    //     },
    //     tooltip: {
    //       style: {
    //         fontSize: "10px",
    //         fontFamily: this.layoutConfig("font-family"),
    //       },
    //       y: {
    //         formatter: function (val) {
    //           // return "Rp" + val;
    //           return "Rp" + parseInt(val).toLocaleString("id-ID");
    //         },
    //       },
    //     },
    //     // colors: '#a72e68',
    //     colors: "#a72e68",
    //     markers: {
    //       strokeWidth: 3,
    //     },
    //     grid: {
    //       show: false,
    //       padding: {
    //         left: 0,
    //         right: 0,
    //       },
    //     },
    //   };
    // },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get(
        "payment-details-chart",
        `${filterParams}`
      );
      this.getSummary();

      let long = response.length;
      let datas = [];
      let lo,
        service = [],
        lab = [],
        medic = [];

      for (lo = 0; lo < long; lo++) {
        if (response[lo].ref_table == 1) {
          service.push(response[lo]);
        } else if (response[lo].ref_table == 2) {
          lab.push(response[lo]);
        } else {
          medic.push(response[lo]);
        }
      }

      if (service.length == 0) {
        service.push({
          ref_name: "Layanan",
          price: 0,
        });
      }
      if (lab.length == 0) {
        lab.push({
          ref_name: "Labolatorium",
          price: 0,
        });
      }
      if (medic.length == 0) {
        medic.push({
          ref_name: "Obat",
          price: 0,
        });
      }
      console.log(service, lab, medic);
      datas.push(service[0]);
      datas.push(lab[0]);
      datas.push(medic[0]);

      console.log("dataaaaaaaa", datas);

      this.series[0].data = datas.map((datas) => parseFloat(datas.price));
      window.arrData = datas.map((datas) => String(datas.ref_name));
      this.chartLoad = true;
      console.log(this.series[0].data);

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: "",
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getSummary();
    this.getData();
    this.getDataService();
    if (this.chartLoad == true) {
      console.log("arr data", this.arr);
    }
    // reference; kt_stats_widget_12_chart
  },
};
</script>
